const WebFont = require('webfontloader');

const webFontLoaderOptions = {
  google: {
    families: ['Domine:700', 'Source Sans 3:400,400i,700,700i'],
  },
  active() {
    sessionStorage.setItem('fonts', 'true');
  },
  timeout: 2000,
};

exports.onClientEntry = () => {
  if (sessionStorage.fonts) {
    document.documentElement.classList.add('wf-active');
  }
  
  WebFont.load(webFontLoaderOptions);
};
